module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"lucilles","defaultLang":"fr-ca","shortenUrlLangs":true,"langs":["fr-ca","en-ca"],"pages":[{"type":"Homepage_catering","match":"/:lang?/","path":"/","component":"/usr/src/app/src/templates/page.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"lucilles-starter-default","short_name":"starter","start_url":"/","background_color":"#1C1A1A","theme_color":"#1C1A1A","display":"minimal-ui","icon":"src/images/favicon.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
